<template>
<tbody class="table-font" v-if="item!=null">
    <tr>
        <td>{{ ((currentpage-1) * noofrec) + (index+1) }}</td>
        <td  class="visible-lg"><a v-if="loginusercheck(35)" href="#" @click="detail(item)">{{ item.generatedid }}</a></td>
        <td><a  href="#" >{{item.name}}</a></td> 
        <td><display-mobiles :mobiles="item.mobiles"></display-mobiles></td>
        <td><display-first-address :addresses="item.addressess"></display-first-address></td>
        <!-- <td>{{  moment(item.date).format("DD-MM-YYYY")}}</td> -->
        <td><i class="fa fa-calendar"></i> {{ moment(item.date).format('DD-MM-YYYY')}}</td>
        <td style="color:chocolate"><i class="fa fa-calendar"></i> {{ moment(item.scheduledate).format('DD-MM-YYYY')}}</td>
        <td style="width:14%">
            <template v-if="item.referraluser!=null">
                {{ item.referraluser.generatedid }}<br>
                {{item.referraluser.name}}
                <display-mobiles :mobiles="item.referraluser.mobiles"></display-mobiles>
            </template>
        </td>
        <td>{{item.creditlimit}}</td>
        <!-- <td >
            <button type="button" class="btn btn-prime btn-xs" style="margin-bottom:5px;"  @click="makedefaulter(item)">
                <div class="font-size:10px;" v-if="item.isdefaulter==0">Make Defaulter ({{item.customer_type}})</div>
                <div class="font-size:10px;" v-else>Remove</div>
            </button>
        </td> -->
        <td style="width:8%" :style="ratingcolor(item)">
            <span style="font-size:18px;text-align:center">{{item.customer_type}}</span>
        </td>
        <td><button class="badge btn-prime btn-xs" @click="showchats()">conversation</button></td>
        <td><button class="badge btn-success btn-xs" @click="showSalechats()">Sale History</button></td>
        <td v-if="loginusercheck(113)"><button class="badge btn-danger btn-xs" @click="Disposecrm()">Dispose</button></td>
    </tr>
    <tr v-if="visitcomments">
        <td colspan="12">
            <crm-conversation :item="item" :dispose="false"></crm-conversation>
        </td>
    </tr>
    <tr v-if="visitcommentssale">
        <td colspan="13">
            <crm-sale :item="item" :show="true"></crm-sale>
        </td>
    </tr>
</tbody>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import Constants from '../../components/utilities/Constants.vue';
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue';
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue';
import CrmConversation from './CrmConversation.vue';
import CrmSale from './CrmSale.vue';
export default {
    props:['item','index','currentpage','noofrec'],
    mixins:[Constants],
    components: {
    	DisplayFirstAddress,DisplayMobiles,
        CrmConversation,CrmSale
    },
    data() {
        return {
            moment:moment,
            visitcomments:false,
            visitcommentssale:false
        }
    },
    computed:{
			...mapGetters([
		     'viewno','loggedinuser',
		    ]),
        },
    methods: {
        showSalechats(){
            this.visitcommentssale=!this.visitcommentssale
        },
        showchats(){
            this.visitcomments=!this.visitcomments
        },
         ratingcolor(item){
            if(item.customer_type=='A'||item.customer_type=='B'){
                return "background:green;color:white;"
            }else if(item.customer_type=='C'){
                return "background:orange;color:white;"
            }else if(item.customer_type=='D' || item.isdefaulter==1){
                return "background:red;color:white;"
            }
        },
        detail(item){
            this.$store.commit('assignedititem',item)
            this.$store.commit('assignviewno',3)
        },
        refresh(){
            this.$parent.refresh();
        },
        Disposecrm(){
            if(confirm("Do you really want to dispose")==true){
                this.$store.commit("assignloadingstatus",1)
                let param={id:this.item.id,column:'isdispose',value:1}
                this.$http.post("api/user/updatefield",param)
                .then((response) => this.ProcessdisposeCRM(response.data))
                .catch((err) => {
                    console.log(err)
                });
            }
        },
        ProcessdisposeCRM(){
            this.$store.commit("assignloadingstatus",0)
            this.$notify({text:'Disposed Successfully',type:'success'})
            this.$parent.refresh();
        },
        

    },
}
</script>