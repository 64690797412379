<template>
    <div>
        <div class="col-md-12" v-if="viewno == 1 || viewno==3">
            <div class="row" style="margin-top:20px">
                <div class="col-md-6">
                    <h3 class="page-header">
                        <i class="fa fa fa-users animated bounceInDown show-info"></i>
                        Scheduled (C.R.M)
                    </h3>
                </div>
                <div class="col-md-6 ">
                    <div class="row" style="padding-top:18px;justify-content:flex-end;">
                        <div class="col-md-2" style="margin-right:25px">
                            <button @click="back()" class="btn btn-primary"><i class="fa fa-backward"></i> Back</button>
                        </div>
                        <div class="col-md-2" style="margin-right:25px;">
                            <button @click="refresh()" class="btn btn-primary"><i class="fa fa-refresh"></i>
                                Refresh</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="viewno == 1">
            <div class="col-md-6">
                <div class="info-box  bg-success-dark  text-white">
                    <div class="info-icon" style="background-color:#7e7e7e">
                        <i class="fa fa-calendar fa-2x"></i>
                    </div>
                    <div class="info-details">
                        <h4>Total Scheduled Customer: <span class="badge bg-danger"
                                style="font-size:medium;">{{ crmschedulecount }}</span></h4>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="info-box  bg-danger-dark  text-white">
                    <div class="info-icon" style="background-color:#7e7e7e">
                        <i class="fa fa-calendar fa-2x"></i>
                    </div>
                    <div class="info-details">
                        <h4>Total Disposed Customer: <span class="badge bg-success"
                                style="font-size:medium;">{{ disposedcount }}</span> </h4>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="info-box  bg-primary-dark  text-white">
                    <div class="info-icon" style="background-color:#7e7e7e">
                        <i class="fa fa-calendar fa-2x"></i>
                    </div>
                    <div class="info-details">
                        <h4>Total Conversation: <span class="badge bg-success"
                                style="font-size:medium;">{{ totalconversation }}</span> </h4>
                    </div>
                </div>
            </div>
        </div>
        <crm-search v-if="viewno==1 || viewno==2 || viewno==3" :displayfor="2"></crm-search>
        <div class="row" v-if="viewno == 1">
            <div class="col-md-12 card-body ft-14 mt-20 card-type">
                <div class="col-md-12 flex-between-row">
                    <h5 style="color:green">Conversation Search</h5>
                    <div>
                        <label for="">Searched Conv : </label>
                        <span class="badge bg-primary">{{ searchconvercount }}</span>
                    </div>
                </div>

                <div class="row mt-30">
                    <div class="col-md-4 row" v-if="loggedinuser?.role<2">
                        <div class="col-md-5">
                            <strong>Enter By</strong>
                        </div>
                        <div class="col-md-7">
                            <select class="form-control col-mb-1" v-model="followedid">
                                <option :value='0'>-----Select------</option>
                                <option v-for="item in employees.filter(property => property.id ==loggedinuser.id )" :value="item.id" :key="item.id">{{ item.name }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4 row" v-if="loggedinuser?.role>=2">
                        <div class="col-md-5">
                            <strong>Enter By</strong>
                        </div>
                        <div class="col-md-7">
                            <select class="form-control col-mb-1" v-model="followedid">
                                <option :value=0>-----Select------</option>
                                <option v-for="item in employees" :value="item.id" :key="item.id">{{ item.name }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4 row">
                        <div class="col-md-6">
                            <strong>Conversation Date From</strong>
                        </div>
                        <div class="col-md-6">
                            <input type="date" class="form-control form-cascade-control input-small"
                                v-model="entrydatefrom">
                        </div>
                    </div>
                    <div class="col-md-4 row">
                        <div class="col-md-6">
                            <strong>Conversation Date To</strong>
                        </div>
                        <div class="col-md-6">
                            <input type="date" class="form-control form-cascade-control input-small" v-model="entrydateto">
                        </div>
                    </div>
                    <div class="col-md-12 flex-around-row text-right">
                        <button v-if="searchconvercount>0" type="button" class="btn bg-primary text-white username btnsearch" @click="viewconversation()" style="margin-top: 31px;">View Conversation</button>
                        <button type="button" class="btn bg-success text-white username btnsearch" @click="conversationfetch()" style="margin-top: 31px;">Search</button>
                    </div>
                </div>
                
            </div>
            <div class="col-md-12  card-body mt-20 ft-14 card-type">
                <div class="row">
                    <div class="col-md-4 row">
                        <div class="col-md-6">
                            <strong>Show Rec.</strong>
                        </div>
                        <div class="col-md-6">
                            <select class="form-control col-mb-1" v-model="noofrec" id="rec" @change="refresh()">
                                <option value=10>10</option>
                                <option value=25>25</option>
                                <option value=50>50</option>
                                <option value=100>100</option>
                            </select>
                        </div>
                    </div>
                    <!-- <div class="col-md-4 row">
                        <div class="col-md-6">
                            <strong>Scheduled Date From</strong>
                        </div>
                        <div class="col-md-6">
                            <input type="date" class="form-control form-cascade-control input-small"
                                v-model="scheduledatefrom" @input="refresh()">
                        </div>
                    </div>
                    <div class="col-md-4 row">
                        <div class="col-md-6">
                            <strong>Scheduled Date To</strong>
                        </div>
                        <div class="col-md-6">
                            <input type="date" class="form-control form-cascade-control input-small"
                                v-model="scheduledateto" @input="refresh()">
                        </div>
                    </div> -->

                </div>
            </div>

            <div class="col-md-12 table-responsive">
                <div class="panel">
                    <div class="panel-body" style="overflow-x:auto;padding-left:0px">
                        <table class="table users-table table-condensed table-hover table-bordered table-sm">
                            <thead class="table-font" style="font-size:13px;">
                                <tr>
                                    <th class="visible-lg">#</th>
                                    <th class="visible-lg">VSDIGI-ID</th>
                                    <th class="visible-lg">Name</th>
                                    <th>Contact</th>
                                    <th>Address</th>
                                    <!-- <th>Landmark</th> -->
                                    <th>Sale date</th>
                                    <th>Scheduled date</th>
                                    <th>Introducer Details</th>
                                    <th>Credit Limit</th>
                                    <th>Ratings</th>
                                    <th>Conversation</th>
                                    <th>Sale</th>
                                    <th v-if="loginusercheck(113)">Dispose</th>
                                </tr>
                            </thead>
                            <crm-scheduled-row v-for="(item, index) in crmschedule" v-bind:key="item.id" :item="item"
                                :index="index" :currentpage="currentpage" :noofrec="noofrec"></crm-scheduled-row>
                        </table>
                        <div class="col-md-12" style="margin-top: 20px">
                            <nav aria-label="Page navigation example" style="text-align: right !important">
                                <paginate style="margin: 0px !important; float: right" :page-count="noofpages"
                                    :click-handler="clickCallback" :prev-text="'&laquo;'" :next-text="'&raquo;'"
                                    :container-class="'pagination'">
                                </paginate>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <customer-detail v-if="viewno == 3"></customer-detail>
        <conversation :conversation="searchconversation" :count="searchconvercount" v-if="viewno==4" :leadtype="0"></conversation>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
import CrmSearch from './CrmSearch.vue';
import CrmScheduledRow from './CrmScheduledRow.vue';
import CustomerDetail from '../customer/CustomerDetail.vue';
import Paginate from "vuejs-paginate";
import moment from 'moment';
import Conversation from '../conversation/Conversation.vue';
export default {
    mixins: [Constants],
    components: {
        CrmSearch, CustomerDetail, Paginate, CrmScheduledRow,Conversation
    },
    data() {
        return {
            activecount: 0,
            defaultercount: 0,
            currentpage: 1,
            noofrec: 10,
            moment: moment,
            remark: '',
            crmdata: [],
            crmcount: 0,
            scheduledateto: '',
            scheduledatefrom: '',
            disposedcount: 0,
            totalconversation: 0,
            enteredbyid: 0,
            entrydatefrom: '',
            entrydateto: '',
            searchconvercount: 0,
            searchconversation:[]
        }
    },
    computed: {
        ...mapGetters([
            'viewno', 'loggedinuser', 'crmschedule', 'crmschedulecount'
        ]),
        noofpages() {
            return Math.ceil(parseInt(this.crmschedulecount) / parseInt(this.noofrec))
        }
    },
    mounted() {
        this.refresh();
    },
    methods: {
        clickCallback(currentpage) {
            this.currentpage = currentpage
            this.$store.commit("assigncurrentpage", currentpage);
            this.refresh();
        },
        conversationfetch() {
            let param = { enteredbyid: this.followedid, entrydatefrom: this.entrydatefrom, entrydateto: this.entrydateto }
            this.$http.post("api/conversation/fetch/byrequest", param)
                .then((response) => this.ProcessConversation(response.data))
                .catch((err) => {
                    this.errorShow(err)
                });
        },
        ProcessConversation(data) {
            this.searchconvercount = data.count
            this.searchconversation = data.conversation
        },
        refresh() {
            this.$store.commit("assignloadingstatus", 1)
            this.$children.searchdetails = true
            let param = {
                noofrec: this.noofrec, currentpage: this.currentpage, scheduledateto: this.scheduledateto,
                scheduledatefrom: this.scheduledatefrom
            }
            this.$http.post("api/crm/scheduled/users", param)
                .then((response) => this.ProcessCRM(response.data))
                .catch((err) => {
                    console.log(err)
                });
            this.$http.post("api/crm/scheduled/users/count")
                .then((response) => this.ProcessCRMcount(response.data))
                .catch((err) => {
                    console.log(err)
                });
            this.$http.post("api/crm/disposed/users/count")
                .then((response) => this.ProcessCRMDisposedcount(response.data))
                .catch((err) => {
                    console.log(err)
                });
            this.$http.post("api/conversation/count/byrequest")
                .then((response) => this.ProcessConversationCount(response.data))
                .catch((err) => {
                    this.errorShow(err)
                });
        },
        ProcessConversationCount(data) {
            this.totalconversation = data
        },
        ProcessCRM(data) {
            this.$store.commit("assignloadingstatus", 0)
            // this.crmdata=data
            this.$store.commit("assigncrmschedule", data)
        },
        ProcessCRMcount(data) {
            // this.crmcount=data
            this.$store.commit("assigncrmschedulecount", data)
        },
        ProcessCRMDisposedcount(data) {
            this.disposedcount = data
        },
        back() {
            this.$store.commit('assignviewno', 1)
        },
        viewconversation(){
            this.$store.commit('assignviewno',4);
        }
    }
}
</script>
<style>
.btn-primary:hover {
    text-decoration: none !important;
    background-color: #3a476b !important;
}

.b-r {
    border-radius: 5px;
}</style>
