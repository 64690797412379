<template>
<div>
    <div class="col-md-12">
        <div class="row" style="margin-top: 20px">
            <div class="col-md-6">
                <h3 class="page-header">
                    <i class="fa fa fa-users animated bounceInDown show-info"></i>
                    Lead Conversations
                </h3>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6" style="padding-top: 18px">
                        <button @click="back()" class="btn btn-primary">
                            <i class='bx bx-left-arrow-alt'></i> Back
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 flex-between-row detailborder">
        <div class="col-md-4">
            <span style="font-weight:700"><i class='bx bx-bookmarks'></i>  Total Conversation:</span> <span >{{this.count}}</span>
        </div>
        <!-- <div class="col-md-4 text-center">
            <span style="font-weight:700"><i class='bx bxs-no-entry' style="color:#f90606"></i>  Total DeActive:</span><span > {{deactiveadvisors}}</span>
        </div>
        <div class="col-md-4 text-right">
            <span style="font-weight:700"><i class='bx bx-search-alt bx-tada' style="color:#121212" ></i>  Searched Advisors:</span><span class="amt">  {{advisorcount}} </span>
        </div> -->
    </div>
    <div class="table-responsive">
        <div class="panel">
            <div class="panel-body" style="overflow-x:auto;padding-left:0px">
                <table class="table users-table table-condensed table-hover table-bordered table-sm">
                    <thead class="table-font" style="font-size:13px;">
                        <tr>
                            <th>S.No</th>
                            <th v-if="leadtype==0">VSDIGI ID</th>
                            <th>Name</th>
                            <th v-if="leadtype==1">Mobile</th>
                            <th>Conversation</th>
                            <!-- <th>Followed By</th> -->
                            <th>Entered BY</th>
                            <th>Schedule Date</th>
                        </tr>
                    </thead>
                    <tbody class="table-font" v-if="leadtype==1">
                        <tr v-for="(item,index) in conversation" v-bind:key="item.id">
                            <td>{{ index+1 }}</td>
                            <td>{{ item.leads?.customername }}</td>
                            <td>{{item.leads?.customermobile}}<br>{{item.leads?.alternatemobile}}</td>
                            <!-- <td>{{ item.leads?.customermobile }}</td> -->
                            <td>{{ item.detail }}</td>
                            <!-- <td><span>{{item.followby!=null?item.followby.name:''}}</span></td> -->
                            <td>{{ item.leads.enteredby!=null?item?.leads?.enteredby?.name:''}}<br>
                                {{ moment(item?.created_at).format("DD-MM-YYYY") }}<br>
                                {{  moment(item?.created_at).format("h:m:s A") }}
                            </td>
                            <td>{{ moment(item.scheduledate).format("DD-MM-YYYY")}}</td>
                            <!-- <td>{{ leadbymethod(item)}}</td> -->
                            <td>{{ item.leadremark}}</td>
                        </tr>
                    </tbody>
                    <tbody class="table-font" v-if="leadtype==0">
                        <tr v-for="(item,index) in conversation" v-bind:key="item.id">
                            <td>{{ index+1 }}</td>
                            <td>{{ item.users?.generatedid }}</td>
                            <td>{{ item.users?.name }}</td>
                            <!-- <td>{{ item.leads?.customermobile }}</td> -->
                            <td>{{ item.detail }}</td>
                            <!-- <td><span>{{item.followby!=null?item.followby.name:''}}</span></td> -->
                            <td>{{ item.enteredby!=null?item.enteredby?.name:''}}<br>
                                {{ moment(item?.created_at).format("DD-MM-YYYY") }}<br>
                                {{  moment(item?.created_at).format("h:m:s A") }}
                            </td>
                            <td>{{ moment(item.scheduledate).format("DD-MM-YYYY")}}</td>
                            <!-- <td>{{ leadbymethod(item)}}</td> -->
                            <td>{{ item.leadremark}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import moment from 'moment';
export default {
    props:['conversation','count','leadtype'],
    data() {
        return {
            moment:moment
        }
    },
    methods: {
        refresh(){

        },
        back(){
            this.$store.commit('assignviewno',1)
        }
    },
}
</script>